import http from '../utils/request'

const serveUrl: any = {
    advancedTags: '/answer/get/advanced/tags',
    advancedList: '/answer/get/advanced/list'
}

// 进阶题标签查询
export const getAdvancedTags = () => {
    return http.request({
        url: serveUrl.advancedTags,
        method: 'GET'
    })
}

// 进阶题详情列表
export const getAdvancedList = (id: number) => {
    return http.request({
        url: serveUrl.advancedList,
        method: 'GET',
        params: {
            id
        }
    })
}