interface counts<T> {
    advanced_answer: number | T
}
export interface AdvancedTagInit {
    id: number
    is_vip: boolean
    order: number
    tag_name: string
    _count: counts<string>
}

export class AdvanedList {
    tagsList: AdvancedTagInit[] = []
}

interface advanListInit {
    advanced_answer: advanced_answerInit
    is_vip: boolean
    tag_name: string
    code: string
    createAt: string
    id: number
    title: string
}
interface advanced_answerInit {
    code: string
    createAt: string
    id: number
    title: string
}
export class AdvancedDetailList {
    adv_list: advanListInit[] = []
    is_vip: boolean = true
    tag_name: string = ''
}
